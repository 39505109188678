var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return Object.keys(_vm.order).length ? _c('div', [_c('bookingInfoCard', {
    attrs: {
      "order": _vm.order
    }
  }, [_c('ValidationSystems', {
    attrs: {
      "order-id": _vm.order.id,
      "validity": _vm.order.validity,
      "showTextCaption": true
    }
  }), _c('v-divider', {
    staticClass: "ml-3 mr-3 mb-5 mt-5",
    attrs: {
      "inset": "",
      "vertical": ""
    }
  })], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "8"
    }
  }, [_vm.invoice ? _c('invoiceInfo', {
    staticClass: "mb-6",
    attrs: {
      "invoice": _vm.invoice
    }
  }) : _vm._e(), _c('Passengers', {
    attrs: {
      "passengers": _vm.order.passengers
    }
  }), _c('div', {
    staticClass: "white elevation-1 rounded pa-5 pt-2 mt-2"
  }, [_c('v-row', [_c('v-col', [_c('h4', {
    staticClass: "mb-1 mt-3"
  }, [_vm._v("Message External")]), _c('vue-editor', {
    attrs: {
      "editor-toolbar": _vm.customToolbar
    },
    model: {
      value: _vm.order.externalMessage,
      callback: function ($$v) {
        _vm.$set(_vm.order, "externalMessage", $$v);
      },
      expression: "order.externalMessage"
    }
  })], 1), _c('v-col', [_c('h4', {
    staticClass: "mb-1 mt-3"
  }, [_vm._v("Message Internal")]), _c('div', {
    attrs: {
      "id": "internalMessage"
    }
  }, [_c('vue-editor', {
    staticStyle: {
      "background-color": "#fff6d9"
    },
    attrs: {
      "editor-toolbar": _vm.customToolbar
    },
    model: {
      value: _vm.order.internalMessage,
      callback: function ($$v) {
        _vm.$set(_vm.order, "internalMessage", $$v);
      },
      expression: "order.internalMessage"
    }
  })], 1)])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-right pt-0"
  }, [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.updateMessage();
      }
    }
  }, [_vm._v(" UPDATE ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_vm.account ? _c('v-card', {
    staticClass: "mb-4",
    staticStyle: {
      "padding": "1rem",
      "box-shadow": "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px !important"
    }
  }, [_c('h3', {
    staticClass: "mango-red--text"
  }, [_vm._v(" Account Details "), _vm.mailMessage ? _c('span', {
    staticClass: "accent--text mail-message",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("( E-mail matching account found )")]) : _vm._e()]), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex gap-4 flex-wrap",
    staticStyle: {
      "gap": "10px"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("Name:")]), _c('span', [_vm._v(_vm._s(_vm.account.firstName) + " " + _vm._s(_vm.account.lastName) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex gap-4 flex-wrap",
    staticStyle: {
      "gap": "10px"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("Email:")]), _c('span', [_vm._v(_vm._s(_vm.account.email) + " ")])])]), _vm.account.phone ? _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex gap-4 flex-wrap",
    staticStyle: {
      "gap": "10px"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("Phone:")]), _c('span', [_vm._v(_vm._s(_vm.account.phone) + " ")])])]) : _vm._e()], 1)], 1) : _vm._e(), _c('Customer', {
    attrs: {
      "order-id": _vm.order.id,
      "customer": _vm.order.customer
    },
    on: {
      "updated": function ($event) {
        return _vm.initial();
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Items', {
    attrs: {
      "initial": _vm.initial,
      "orderStatus": _vm.order.status,
      "orderId": _vm.order.id,
      "items": _vm.order.items,
      "printedItemIds": _vm.order.printedItemIds && _vm.order.printedItemIds,
      "order-is-paid": _vm.order.isPaid,
      "bookings": _vm.order.bookings,
      "base-currency": _vm.order.calculatedPrice.base.currency,
      "tenant-id": _vm.order.seller.tenant.id,
      "tenant-name": _vm.order.seller.tenant.name,
      "can-use-giftcard": _vm.order.seller.tenant.canUseGiftCards,
      "order-fully-paid": _vm.order.isFullyPaid,
      "locale": _vm.order.locale,
      "is-international": _vm.order.isInternational
    },
    on: {
      "itemChange": _vm.initial
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_c('span', {
    staticClass: "mango-red"
  }, [_vm._v("Activity log ")]), _vm._v("(" + _vm._s(_vm.order.events.length) + ")")])])])]), _c('v-card', [_c('v-card-text', {
    staticClass: "elevation-1"
  }, [_c('v-timeline', {
    attrs: {
      "dense": "",
      "align-top": ""
    }
  }, [_c('v-timeline-item', {
    staticClass: "mb-12",
    attrs: {
      "color": "secondary",
      "icon": "mdi-account",
      "fill-dot": "",
      "large": ""
    }
  }, [_c('ValidationObserver', {
    ref: "eventObserver"
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          attrs: {
            "error-messages": errors,
            "label": "Leave a comment...",
            "hide-details": "",
            "solo": ""
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function () {
              return [_c('v-btn', {
                staticClass: "mx-0 accent",
                attrs: {
                  "loading": _vm.event.loading,
                  "depressed": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.createEvent();
                  }
                }
              }, [_vm._v("Post ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.event.comment,
            callback: function ($$v) {
              _vm.$set(_vm.event, "comment", $$v);
            },
            expression: "event.comment"
          }
        })];
      }
    }], null, false, 834621368)
  })], 1)], 1), _c('event-list', {
    attrs: {
      "events": _vm.order.events
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "loading": _vm.isLoading,
      "width": "500px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.orderFilesDialog = false;
      }
    },
    model: {
      value: _vm.orderFilesDialog,
      callback: function ($$v) {
        _vm.orderFilesDialog = $$v;
      },
      expression: "orderFilesDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Order Files")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.orderFilesDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('orderFiles', {
    key: Math.random(),
    attrs: {
      "files": _vm.orderFilesData
    }
  })], 1)], 1), _c('float-buttons', {
    attrs: {
      "isCompany": _vm.order.invoiceId,
      "customer": _vm.order.customer,
      "order-locale": _vm.order.locale,
      "order-id": _vm.orderId,
      "order-is-paid": _vm.order['isPaid'],
      "order-is-fully-paid": _vm.order['isFullyPaid'],
      "order-is-anonymized": _vm.order['isAnonymized'],
      "is-archived-order": _vm.order.archived,
      "order-contains-rail-passes": _vm.includesRailPass(_vm.order.items)
    },
    on: {
      "get-files": function ($event) {
        return _vm.getOrderFiles($event);
      },
      "initials": _vm.initial
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }