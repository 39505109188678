<template>
  <div class="pa-4">
    <v-card v-if="sortedTickets && sortedTickets.length > 0" class="mb-4">
      <toolbarTitle title="Order Tickets" />
      <v-list dense>
        <v-list-item v-for="(item, index) in sortedTickets" :key="index">
          <v-list-item-content class="font-weight-bold">{{ getTicketName(item) }}</v-list-item-content>
          <v-list-item-content class="justify-end">
            <v-btn color="accent" class="white--text" style="max-width: 130px" @click="openFile(item.accessUrl)">
              View File
              <v-icon right dark> mdi-eye </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card v-if="sortedFiles && sortedFiles.length > 0">
      <toolbarTitle title="Order Files" />
      <v-list dense>
        <v-list-item v-for="(item, index) in sortedFiles" :key="index">
          <v-list-item-content class="font-weight-bold">{{ getTicketName(item) }}</v-list-item-content>
          <v-list-item-content class="justify-end">
            <v-btn color="accent" class="white--text" style="max-width: 130px" @click="openFile(item.accessUrl)">
              View File
              <v-icon right dark> mdi-eye </v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import toolbarTitle from "@/components/common/toolbarTitle";
export default {
  components: {
    toolbarTitle,
  },
  props: ["files"],
  data() {
    return {
      fileList: null,
      sortedTickets: null,
      sortedFiles: null,
    };
  },
  mounted() {
    this.fileList = this.files;
    this.sortedTickets = this.sortTickets(this.fileList);
    this.sortedFiles = this.sortFiles(this.fileList);
  },
  methods: {
    getTicketName(item) {
      if (item.category == "ticket") {
        return "Ticket " + item.fileName;
      } else if (item.category == "taxi") {
        return "Taxi Booking";
      }
      return this.fixFilename(item.fileName);
    },
    openFile(value) {
      window.open(value);
    },
    fixFilename(filename) {
      return filename.replaceAll("-", " ").replaceAll("_", " ").replace(".pdf", "");
    },
    sortFiles(fileList) {
      const categoryOrder = {
        ticket: 1,
        mobile_pass: 2,
        gift_card: 3,
        receipt: 4,
        information: 5,
      };

      return fileList
        .map((item) => ({
          rowId: categoryOrder[item.category] || 99,
          ...item,
        }))
        .sort((x, y) => x.rowId - y.rowId)
        .filter((x) => ["receipt", "information"].includes(x.category));
    },
    sortTickets(fileList) {
      const categoryOrder = {
        ticket: 1,
        mobile_pass: 2,
        gift_card: 3,
        taxi: 4,
        receipt: 5,
        information: 6,
      };

      return fileList
        .map((item) => ({
          rowId: categoryOrder[item.category] || 99,
          ...item,
        }))
        .sort((x, y) => x.rowId - y.rowId)
        .filter((x) => ["ticket", "mobile_pass", "gift_card", "taxi"].includes(x.category));
    },
  },
};
</script>
